import React from "react";

import Layout from "../components/layout";
import SolutionsComponent from "../components/solutions/solutions";

export default function Solutions() {
  return (
    <Layout>
      <div className="solutions-container">
        <div>
          <SolutionsComponent />
        </div>
      </div>
    </Layout>
  );
}
