import React from "react";
import { useIntl } from "gatsby-plugin-intl";

import Dekiru from "../../../assets/dekiru.svg";
import Diversity from "../../../assets/diversity.svg";
import Innovation from "../../../assets/innovation.svg";
import Tailormade from "../../../assets/tailormade.svg";
import GetIntlArrayObject from "../../utils/GetIntlArrayObject";
import GetIntlSinglePrimitive from "../../utils/GetIntlSinglePrimitive";

import Solution from "./solution";

export default function Solutions() {
  const intl = useIntl();
  const title = GetIntlSinglePrimitive(intl, "solutions.title");
  const text = GetIntlArrayObject("solutions.s", intl.messages);
  /**
   *
   * @param index index matches placement in language file (sv.json/en.json)
   */

  const getSvg = (index: string) => {
    if (index === "1") {
      return <Diversity />;
    }

    if (index === "2") {
      return <Innovation />;
    }

    if (index === "3") {
      return <Tailormade />;
    }

    if (index === "4") {
      return <Dekiru />;
    }

    return <></>;
  };

  return (
    <div className="d-content solutions">
      <div className="solutions__intro pt-5 mt-3">
        <p className="title is-size-1 is-size-2-mobile is-spaced has-text-centered has-text-light mt-6">{title}</p>
      </div>
      {text.map((t, index) => {
        if (index % 2 === 0) {
          return <Solution key={t.subtitle} reverse Svg={getSvg(t.index)} text={t.text} title={t.subtitle} />;
        }

        return <Solution key={t.subtitle} Svg={getSvg(t.index)} text={t.text} title={t.subtitle} />;
      })}
    </div>
  );
}
