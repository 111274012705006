import React from "react";
import Fade from "react-reveal/Fade";
import useMedia from "Src/hooks/useMedia";

interface Props {
  title: string;
  text: string;
  reverse?: boolean;
  Svg: JSX.Element;
}

export default function Block({ title, text, reverse, Svg }: Props) {
  const desktopScreen = useMedia("(min-width: 1024px)");

  const figure = () => {
    return (
      <div className="column is-6-widescreen is-12-mobile">
        <div className={`solutions__figure ${reverse ? "solutions__figure--left" : "solutions__figure--right"}`}>
          <Fade duration={1000} left={desktopScreen && !reverse} right={desktopScreen && reverse} up={!desktopScreen}>
            {Svg}
          </Fade>
        </div>
      </div>
    );
  };

  return (
    <div className="solutions__container">
      <div className={`columns ${reverse ? "reverse-row-order" : ""} is-centered`}>
        {!reverse && figure()}
        <div className="column is-6-widescreen is-12-mobile">
          <Fade duration={1000} left={desktopScreen && reverse} right={desktopScreen && !reverse} up={!desktopScreen}>
            {/* Extra empty div is needed for desired Slide cascade effect */}
            <div>
              <div className="solutions__title">
                <div className={`solutions__title-block ${reverse ? "solutions__title-block--left" : "solutions__title-block--right"}`} />
                <p className="solutions__title-text title is-size-2 is-size-3-mobile has-text-light has-text-weight-bold">{title}</p>
              </div>
              <p className="ingress has-text-light">{text}</p>
            </div>
          </Fade>
        </div>
        {reverse && figure()}
      </div>
    </div>
  );
}
